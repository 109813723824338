import { useEffect, useState } from 'react';
import {
  Col, Row, Image,
} from 'react-bootstrap';

const ContentList = ({ dragUrl, contentSelected }) => {
  const [folderCrumb, setFolderCrumb] = useState([]);
  const [showContentData, setShowContentData] = useState(false);
  const [contentImages, setContentImages] = useState([]);

  const importAll = (r) => {
    // const images = {};
    // r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    // return images;
    const images = [];
    r.keys().forEach((item, index) => { images.push(r(item)); });
    return images;
  };

  const getContentImages = () => {
    const images = importAll(require.context('../../../assets/images/virtual/content/themecollections/displaystands', false, /\.(png|jpe?g|svg)$/));
    setContentImages(images);
  };

  useEffect(() => {
    if (contentSelected && contentSelected?.name) {
      folderCrumb.push(contentSelected.name);
      setFolderCrumb(folderCrumb);
      if (contentSelected.name === 'Display Stands' || contentSelected.name === 'Other stuff') {
        getContentImages();
        setShowContentData(true);
      } else {
        setContentImages([]);
        setShowContentData(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentSelected]);

  return (
    <>
      <Row>
        <Col xs={12} md={12}>
          <div className="d-flex align-items-center mb_2 py-2 myProduct">
            <h6 className="text-white mb-0 me-2"> &nbsp; </h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} className="productListTable">
          {folderCrumb && folderCrumb.length > 0 && <p>{folderCrumb.join(' / ')}</p>}
          {showContentData && contentImages && contentImages.map((contentImage, i) => (
            <Image
              key={i}
              src={contentImage}
              thumbnail
              alt="image"
              draggable={true}
              onDragStart={(e) => {
                // eslint-disable-next-line
                dragUrl.current = e.target.src;
              }}
              style={{ width: '100px', height: '100px', margin: '5px' }}
            />
          ))}
        </Col>
      </Row>
    </>
  );
};

export default ContentList;
